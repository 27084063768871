const util = Ingtech.util;

/**
 * @typedef DropDown.Config
 * @property {string} [id]
 * @property {string[]} [class]
 * @property {string} [minWidth]
 * @property {boolean} [dropdown]
 * @property {object} [target]
 * @property {function} [show]
 * @property {function} [click]
 * @property {function} [hide]
 * @property {DropDown.HeaderConfig} [header]
 * @property {DropDown.ButtonConfig[]} [buttons]
 * 
 */

/**
 * @typedef DropDown.HeaderConfig
 * @property {string} [id]
 * @property {string} [class]
 * @property {string} [label]
 * 
 */

/**
 * @typedef DropDown.ButtonConfig
 * @property {string} [tag]
 * @property {string} [className]
 * @property {string} [name]
 * @property {string} [label]
 * @property {string} [href]
 * @property {object} [attr]
 * @property {function} [action]
 * @property {function} [init]
 * @property {function} [preInit]
 * @property {string} [type]
 * @property {function} [config]
 * @property {boolean} [disabled]
 * @property {boolean} [newTab]
 * 
 * @property {DropDown.ButtonConfig[]} [buttons]
 */

class DropDown {
  /**
   * 
   * @param {DropDown.Config} config 
   */
  constructor(config, $parent) {
    Object.assign(this, config);

    let minWidth = config.minWidth || Math.min($parent.width(), 150);

    this.$dropdown = $(`
      <div${config.id ? ` id="${config.id}"` : ''} class="dropdown context-menu${config.class ? ` ${config.class.join(' ')}` : ""}" style="position:static;" >
        <ul class="dropdown-menu" style="min-width:${minWidth}px;">
        </ul>
      </div>
    `);

    let dropdownMenu = this.$dropdown.children('.dropdown-menu')

    if (config.header) {
      let header = config.header;

      dropdownMenu.append(`
      <li${header.id ? ` id="${header.id}"` : ''} class="dropdown-header ${header.class}">
        ${header.label}
      </li>
      `);
    }

    for (let button of config.buttons) {
      dropdownMenu.append(DropDown.parseButton(button, config));
    }

    $parent.append(this.$dropdown);

    this.$dropdown.on('contextmenu.show', (ev, dropdownMenu, target) => {
      config.target = target;

      if (config.show instanceof Function) {
        config.show.call(this.$dropdown, ev, dropdownMenu, target, config);

        for (let button of config.buttons) {
          let li = dropdownMenu.find(`#${button.id}`);

          if (button.config instanceof Function) {
            const newConfig = button.config.call(this.$dropdown, ev, button, dropdownMenu, config);

            if (newConfig) {
              Object.assign(button, newConfig);

              button.$element.replaceWith(DropDown.parseButton(button, config));
            }
          }

          if (button.show instanceof Function) button.show.call(li.get(0), ev, button, dropdownMenu, config);
        }
      }
    });

    if (config.click instanceof Function) {
      this.$dropdown.on('contextmenu.click', (ev, dropdownMenu, link) => {
        config.click.call(this.$dropdown, ev, dropdownMenu, link, config);
      });
    }

    if (config.hide instanceof Function) {
      this.$dropdown.on('contextmenu.hide', (ev, dropdownMenu) => {
        config.hide.call(this.$dropdown, ev, dropdownMenu, config);
      });
    }

    if (config.dropdown) {
      $parent.click(ev => {
        let showEvent = $.Event('contextmenu.show');
        this.$dropdown.trigger(showEvent, [dropdownMenu, ev.target]);

        if (!showEvent.isDefaultPrevented()) {
          $('.dropdown-menu').hide();

          ev.stopPropagation();
          ev.preventDefault();

          $('body').append(dropdownMenu.detach());

          var { top, left } = $(ev.target).offset();

          // make sure to place it where it would normally go (this could be improved)
          dropdownMenu.css({
            'position': "fixed",
            'display': 'block',
            'top': 10,
            'left': Math.min(left, $(window).width() - dropdownMenu.width() - 5)
          });
        }
      });

      this.$dropdown.data('disableContextEvent', true);
    }

    this.$dropdown.advDropdown();
  }


  /**
   * 
   * @param {DropDown.ButtonConfig[]} buttons
   * @param {object} [config]
   */
  static parseButton(btnConf, config) {
    if (btnConf == 'divider' || btnConf.className == "divider" || btnConf.type == "divider") {
      if (btnConf.className == 'divider') btnConf.className = null;

      const divider = $(`<li class="divider ${btnConf.className || ''}"></li>`);

      if (typeof btnConf != 'string') {
        btnConf.$element = divider;
      }

      return divider;
    }

    else if (btnConf.type == 'submenu') {
      let submenu = $(`<li class="dropdown-submenu ${btnConf.className}">
        <a class="title" tabindex="-1" href="#">${btnConf.label || btnConf.name}</a>
        <ul class="dropdown-menu">
          
        </ul>
      </li>`);

      let submenuMenu = submenu.children('.dropdown-menu');
      submenu.attr(btnConf.attr || {});

      for (let btnSubConf of btnConf.buttons || []) {
        let btn = this.parseButton(btnSubConf).appendTo(submenu.children('.dropdown-menu'));

        // let oldClick = btn.click;
        // btn.click(() => {
        //   console.log('test')
        //   // submenuMenu.hide();
        //   // $(window).trigger($.Event('hide.submenu', { target: submenuMenu, relatedTarget: submenu }));
        // });
      }


      let isOpened = false;
      let cd;

      let hoverIn = (e) => {
        if (submenu.hasClass('disabled')) return;

        if (!isOpened) {
          cd = setTimeout(() => {
            isOpened = true;
            cd = null;

            submenuMenu.show();
            $(window).trigger($.Event('show.submenu', { target: submenuMenu, relatedTarget: submenu }));
          }, 500);
        } else {
          if (cd) clearTimeout(cd);
        }
      };

      let hoverOut = (e) => {
        if (submenu.hasClass('disabled')) return;

        if (isOpened) {
          cd = setTimeout(() => {
            isOpened = false;
            cd = null;

            submenuMenu.hide();
            $(window).trigger($.Event('hide.submenu', { target: submenuMenu, relatedTarget: submenu }));
          }, 500);
        } else {
          if (cd) clearTimeout(cd);
        }
      };

      submenu.children('a.title').hover(hoverIn, hoverOut).click(e => {
        e.preventDefault();
        e.stopPropagation();

        if (submenu.hasClass('disabled')) return;

        submenuMenu.show();
        $(window).trigger($.Event('show.submenu', { target: submenuMenu, relatedTarget: submenu }));

        isOpened = submenuMenu.is(':visible');
        if (cd) clearTimeout(cd);
      });
      submenuMenu.hover(hoverIn, hoverOut);

      btnConf.$element = submenu;

      return submenu;
    }

    else {
      btnConf.tag = btnConf.tag || 'li';
      btnConf.href = btnConf.href || 'javascript:;';

      if (btnConf.preInit) {
        let override = btnConf.preInit(btnConf);
        if (override == false) return;
      }

      let btn = $(`
        <${btnConf.tag}${btnConf.id ? ` id="${btnConf.id}"` : ''}${btnConf.class ? ` class="${btnConf.class}"` : ""}>
          <a href="${btnConf.href || 'javascript:;'}"${btnConf.name ? ` name="${btnConf.name}"` : ''}${btnConf.newTab ? ' target="_blank"' : ''}>${btnConf.label || btnConf.name}</a>
        </${btnConf.tag}>
      `);

      if (btnConf.disabled) btn.addClass('disabled');

      btn.attr(btnConf.attr || {});

      if (btnConf.init) btnConf.init(config, btn, btnConf);

      btn.click(function (ev) {
        if (!$(this).hasClass('disabled')) {
          const action = btnConf.action || btnConf.click;

          if (action instanceof Function) {
            action.call(this, ev, btnConf, dropdownMenu, config);
          }
        } else {
          ev.stopPropagation();
          ev.preventDefault();
        }
      });

      btnConf.$element = btn;

      return btn;
    }
  };
}

module.exports = DropDown;


// hold onto the drop down menu                                             
var dropdownMenu;

// and when you show it, move it to the body                                     
$(window).on('show.bs.dropdown', function (e) {

  // grab the menu 
  dropdownMenu = $($(e.target).find('.dropdown-menu').get(0));

  // detach it and append it to the body
  $('body').append(dropdownMenu.detach());

  // grab the new offset position
  var { top, left } = $(e.target).offset();

  // make sure to place it where it would normally go (this could be improved)
  dropdownMenu.css({
    'display': 'block',
    'top': window.innerWidth <= 768 ? top + $(e.target).outerHeight() + 0 : $(window).height() - dropdownMenu.height() - 20,
    'left': Math.min(left, $(window).width() - dropdownMenu.width() - 10)
  });
});

// and when you hide it, reattach the drop down, and hide it normally                                                   
$(window).on('hide.bs.dropdown', function (e) {
  $(e.target).append(dropdownMenu.detach());
  dropdownMenu.hide();
  dropdownMenu.find('ul.dropdown-menu').hide();
});


$(window).on('show.submenu', function (e) {
  let submenu = e.relatedTarget;
  let submenuMenu = e.target;

  // $('body').append(submenuMenu.detach());

  var { top, left } = submenu.offset();
  let mLeft;

  if (left + submenu.width() + submenuMenu.width() < $(window).width()) {
    mLeft = submenu.width();
  } else {
    mLeft = -submenuMenu.width();
  }
  // submenu.toggleClass('left', mLeft < 0);

  submenuMenu.css({
    'display': 'block',
    'top': -4,
    'left': mLeft
  });
});

$(window).on('hide.submenu', function (e) {
  let submenu = e.relatedTarget;
  let submenuMenu = e.target;

  // $(submenu).append(submenuMenu.detach());
});




jQuery.fn.extend({
  advDropdown: function () {
    let dropdown = $(this);
    let dropdownMenu = dropdown.children('.dropdown-menu');

    let parent = dropdown.parent();

    if (dropdown.attr('data-parent')) {
      parent = $(dropdown.attr('data-parent'));
    }

    if (!dropdown.data('disableContextEvent')) {
      parent.contextmenu((ev, x, y) => {
        let showEvent = $.Event('contextmenu.show');
        dropdown.trigger(showEvent, [dropdownMenu, ev.target]);

        if (!showEvent.isDefaultPrevented()) {
          $('.dropdown-menu').hide();

          ev.stopPropagation();
          ev.preventDefault();

          $('body').append(dropdownMenu.detach());

          dropdownMenu.css({
            display: "block",
            left: Math.min(x || ev.pageX, $(window).width() - dropdownMenu.width()),
            top: Math.min(y || ev.pageY, $(window).height() - dropdownMenu.outerHeight()) // y || ev.pageY
          });
        }
      });
    }

    dropdownMenu.on("click", "a", function (ev) {
      if ($(this).hasClass('disabled') || $(this).parent().hasClass('disabled')) {
        ev.preventDefault();
        ev.stopPropagation();

        return;
      }

      let clickEvent = $.Event('contextmenu.click');
      dropdown.trigger(clickEvent, [dropdownMenu, $(this)]);

      if (!clickEvent.isDefaultPrevented()) {
        let hideEvent = $.Event('contextmenu.hide');
        dropdown.trigger(hideEvent);

        if (!clickEvent.isDefaultPrevented()) {
          dropdownMenu.hide();
        }
      }
    });

    dropdownMenu.contextmenu(ev => {
      ev.preventDefault();
    });

    $(window).on('mousedown mousewheel', ev => {
      if (dropdownMenu.is(':visible')) {
        if (!$(ev.target).parents('.dropdown-menu').toArray().some(el => el == dropdownMenu[0])) {
          let hideEvent = $.Event('contextmenu.hide');
          dropdown.trigger(hideEvent, [dropdownMenu]);

          dropdownMenu.hide();
        }
      }
    });
  },

  appendAdvDropdown: function (config) {
    config = new DropDown(config, this);

    return config.$dropdown;
  }
});
{/* <div id="map-context-menu" class="dropdown context-menu" style="position:static">
  <ul class="dropdown-menu">
    <li class="zone dropdown-header">Zone: <span class="zone-name"></span></li>
    <li class="zone divider"></li>
    <li class="zone"><a href="javascript:;" name="details-zone"><%= req.i18n.__ZONE_DETAILS %></a></li>
    <li class="zone"><a href="javascript:;" name="edit-zone"><%= req.i18n.__EDIT_ZONE %></a></li>
    <li class="zone"><a href="javascript:;" name="delete-zone"><%= req.i18n.__DELETE_ZONE %></a></li>
    <li class="zone-new"><a href="javascript:;" name="new-zone"><%= req.i18n.__CREATE_ZONE_HERE %></a></li>
  </ul>
</div> */}


$(document).ready(() => {
  $('.dropdown.context-menu').each((i, node) => {
    let dropdown = $(node);
    dropdown.advDropdown();
  });
});
const oldDefaultApply = $.fn.select2.defaults.apply;

function UtilsExtend(ChildClass, SuperClass) {
  var __hasProp = {}.hasOwnProperty;

  function BaseConstructor() {
    this.constructor = ChildClass;
  }

  for (var key in SuperClass) {
    if (__hasProp.call(SuperClass, key)) {
      ChildClass[key] = SuperClass[key];
    }
  }

  BaseConstructor.prototype = SuperClass.prototype;
  ChildClass.prototype = new BaseConstructor();
  ChildClass.__super__ = SuperClass.prototype;

  return ChildClass;
};


function applyCache(result, options) {
  if (!options.cache) return result;

  const dataAdapter = result.dataAdapter;

  function CacheAdapter($element, options) {
    this._cache = options.get('cache');

    CacheAdapter.__super__.constructor.call(this, $element, options);
  }

  UtilsExtend(CacheAdapter, dataAdapter);
    
  CacheAdapter.prototype.query = async function (params, callback) {
    const self = this;
  
    if (self._cachedResults) {
      if (self._cachedResults.expireAt === true || self._cachedResults.expireAt > new Date().getTime()) {
        const results = { ...self._cachedResults };
        results.results = results.results.filter((result) => self.matches(params, result));

        return callback(results);
      }
    }

    function cacheCallback(results) {
      if (self._cache) self._cachedResults = {
        expireAt: typeof self._cache == 'number' ? new Date().getTime() + self._cache * 1000 : !!self._cache,
        ...results
      }

      callback(results);
    }

    this.__proto__.__proto__.query.call(this, params, cacheCallback);
  }

  result.dataAdapter = CacheAdapter;
}


function applyAsyncData(result, options, dataCallback) {
  const dataAdapter = result.dataAdapter;
  
  if (!dataCallback) return result;

  function AsyncAdapter($element, options) {
    AsyncAdapter.__super__.constructor.call(this, $element, options);
  }

  UtilsExtend(AsyncAdapter, dataAdapter);

  AsyncAdapter.prototype.query = async function (params, callback) {
    const self = this;
  
    try {   
      let results = dataCallback(params)
      if (results instanceof Promise) results = await results;
      if (results instanceof Array) results = { results };
  
      // Check to make sure that the response included a `results` key.
      if (!results || !results.results || !Array.isArray(results.results)) {
        console.error(
          'Select2: The data results did not return an array in the ' +
          '`results` key of the response.',
          results
        );
      }
  
      results.results = results.results.filter((result) => self.matches(params, result));
  
      callback(results);
    } catch (err) {
      console.error('Select2: Error while executing data function', err);
    }
  }

  result.dataAdapter = AsyncAdapter;
}



$.fn.select2.defaults.apply = function (options) {
  const dataCallback = options.data instanceof Function ? options.data : null;
  if (dataCallback) options.data = [];

  const result = oldDefaultApply.call(this, options);
  
  applyAsyncData(result, options, dataCallback);
  applyCache(result, options);

  return result;
}
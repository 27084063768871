const util = Ingtech.util;

/**
 * The jQuery plugin namespace.
 * @external "jQuery.fn"
 * @see {@link http://learn.jquery.com/plugins/|jQuery Plugins}
 */

/**
 *
 * @function external:"jQuery.fn".label
 *
 * @param {*} args
 * @return {*} 
 */
jQuery.fn.label = function (...args) {
  if (args.length > 0) {
    let names = [];
    args.forEach(name =>
      names = names.concat(name.split(',').map(name => name.trim())));

    return this.find(names.map(name => `[for="${name}"]`).join(', '));
  } else {
    return $(`[for="${this.name()}"]`);
  }
};

/**
 *
 * @function external:"jQuery.fn".input
 *
 * @param {*} args
 * @return {*} 
 */
jQuery.fn.input = function (...args) {
  let names = [];
  args.forEach(name =>
    names = names.concat(name.split(',').map(name => name.trim())));

  return this.find(names.map(name => `[name="${name}"]`).join(', '));
};

jQuery.fn.inputs = function () {
  return this.find('input, select, textarea, .form-control');
};

jQuery.fn.name = function () {
  return this.attr('name');
};

jQuery.fn.renameTooltip = function (text) {
  this.tooltip('hide')
    .attr('data-original-title', text);

  setTimeout((obj) => {
    if (obj && obj.is(':hover')) obj.tooltip('show');
  }, 200, this);
};

jQuery.fn.tagName = function () {
  let name = this.prop("tagName");

  return name ? name.toLowerCase() : undefined;
};

jQuery.fn.scrollHeight = function () {
  return this.prop('scrollHeight');
};

jQuery.fn.scrollBottom = function (value) {
  if (value === undefined) {
    return Math.round(this.scrollHeight() - (this.height() + this.scrollTop()));
  } else {
    return this.scrollTop(this.scrollHeight() - this.height() - value);
  }
};

jQuery.fn.scrollTopAnim = function (value, duration = 500) {
  return this.animate({
    scrollTop: value
  }, duration);
};

jQuery.fn.scrollBottomAnim = function (value, duration = 500) {
  return this.animate({
    scrollTop: this.scrollHeight() - this.height() - value
  }, duration);
};

jQuery.fn.isCheckbox = function () {
  return this.tagName() == 'input' && this.prop('type') == 'checkbox';
};

jQuery.fn.checked = function (checked) {
  return arguments.length ? this.prop('checked', checked) : this.prop('checked');
};

jQuery.fn.select2SelectOrCreate = function (id, text, tag = false, data = {}) {
  if (text === undefined) text = id;

  if (this.find('option').toArray().find(el => el.value == id)) {
    this.val(id).change();
  } else {
    this.append(`<option value="${util.escapeHtml(id)}"${tag ? 'data-select2-tag="true"' : ''}>${util.escapeHtml(text)}</option>`);
    
    const newData = this.select2('data')?.find(el => el.id == id) || {};
    Object.assign(newData, data);

    this.val(id).change();
  }

  return this;
};
jQuery.fn.select2Rename = function (id, text, tag = false, data = {}) {
  const selected = this.val();
  const selectedText = selected ? this.select2('data').find(el => el.id == selected).text : null;

  this.html('');

  this.select2SelectOrCreate(selected, selected == id ? text : selectedText, tag = false, data = {});
}

jQuery.fn.select2Show = function () {
  this.show();
  this.data('select2').$element.next().show();

  return this;
};

jQuery.fn.select2Hide = function () {
  this.hide();
  this.data('select2').$element.next().hide();

  return this;
};

jQuery.fn.select2Toggle = function (toggle) {
  toggle = toggle != null ? toggle : this.is(':hidden');

  this.toggle(toggle);
  this.data('select2').$element.next().toggle(toggle);

  return this;
};

const NOT_FINISHED = Symbol('not-finished');

jQuery.fn.loadingAnim = async function (promise, minDuration = 250, blockClass = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const start = Date.now();
      if (promise instanceof Function) {
        let result = promise();

        promise = result instanceof Promise ? result : Promise.resolve(result);
      }

      if (!(promise instanceof Promise)) return;

      let isAlreadyFinished = await Promise.race([promise, util.sleep(0, NOT_FINISHED)]);
      if (isAlreadyFinished !== NOT_FINISHED)
        return resolve(await promise);

      let finished = await Promise.race([promise, util.sleep(50, NOT_FINISHED)]);

      if (finished === NOT_FINISHED) {
        this.startLoadingAnim(blockClass);

        await Promise.all([
          promise,
          util.sleep(minDuration)
        ]).finally(async () => {
          resolve(await promise);
        });
      } else {
        resolve(await promise);
      }
    } catch (err) {
      reject(err);
    } finally {
      this.stopLoadingAnim();
    }
  });
};

jQuery.fn.startLoadingAnim = function (blockClass) {
  if (this.find('.refresh-block').length > 0) return;

  $(`<div class="refresh-block ${blockClass}"><span class="refresh-loader"><i class="fa fa-spinner fa-spin"></i></span></div>`)
    .appendTo(this);
};

jQuery.fn.stopLoadingAnim = function () {
  this.find('.refresh-block').remove();
};

jQuery.fn.addRequiredAsterisk = function () {
  let validator = $(this).data('validator');

  if (validator) {
    $(this).clearRequiredAsterisk();

    for (let [name, rules] of Object.entries(validator.settings.rules)) {
      let $input = $(this).input(name);

      if (rules.required && !$input.prop('disabled') && !$input.prop('readonly')) {
        if (rules.required instanceof Function) {
          if (!rules.required($input[0])) continue;
        }

        let $formGroup = $input.closest('.form-group');


        if ($formGroup.length > 0) {
          let label = $formGroup.tagName() == 'td' ? $input.closest('tr').find('td:first-child') : $formGroup.find('label');

          label.append('<sup class="required-asterisk"> *</sup>');
        }
      }
    }
  }
};
jQuery.fn.clearRequiredAsterisk = function () {
  $(this).find('.required-asterisk').remove();
};

jQuery.fn.hasSelection = function () {
  return window.getSelection().toString() != '' && $(this).has($(window.getSelection().anchorNode)).length > 0;
};

jQuery.fn.reset = function () {
  $(this).closest('.form-group').removeClass('has-error');
  $(this).closest('.form-group').find('.help-block').remove();
}

jQuery.fn.disable = function () {
  $(this).prop('disabled', true);
  $(this).addClass('disabled');

  return this;
}

jQuery.fn.enable = function () {
  $(this).prop('disabled', false);
  $(this).attr('disabled', false);
  $(this).removeClass('disabled');

  return this;
}

jQuery.fn.toggleDisabled = function (disabled) {
  if (disabled === undefined) {
    disabled = !($(this).prop('disabled') || $(this).hasClass('disabled') || $(this).attr('disabled') == 'disabled');
  }

  if (disabled) {
    return $(this).disable();
  } else {
    return $(this).enable();
  }
}


function getTextWidth(text, font) {
  // re-use canvas object for better performance
  var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  var context = canvas.getContext("2d");
  context.font = font;
  var metrics = context.measureText(text);

  return metrics.width;
}

jQuery.fn.suffix = function (suffix, offset = 0) {
  const $suffix = $(`<span class="suffix">${typeof suffix == 'string' ? suffix : ''}</span>`);
  $(this).after($suffix);

  $(this).on('input change ready', function (ev) {
    let suffixElement = $suffix.get(0);

    const width = getTextWidth(this.value, '14px Open Sans');
    suffixElement.style.left = width + 25 + offset + 'px';

    if (suffix instanceof Function) {
      const text = suffix(this.value);
      $suffix.text(text);
    }

    $suffix.toggle(!!this.value);
  }).trigger('change');
}

jQuery.fn.hasScrollBar = function () {
  return this.get(0).scrollHeight > this.height();
}

jQuery.fn.getScrollBarWidth = function () {
  return this.get(0).offsetWidth - this.get(0).clientWidth;
}
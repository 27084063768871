$.validator.addMethod("valid", function (value, element, valid, method) {
  method = typeof method === "string" && method || "valid";
  
  if (!(valid) instanceof Promise) return valid;

  let previous = this.previousValue(element, method);
  let validator;

  if (!this.settings.messages[element.name]) {
    this.settings.messages[element.name] = {};
  }

  previous.originalMessage = previous.originalMessage || this.settings.messages[element.name][method];
  this.settings.messages[element.name][method] = previous.message;

  previous.valid = null;
  validator = this;
  this.startRequest(element);

  const evaluate = async () => {
    let submitted, errors, message, result;
    let isValid = false;

    try {
      result = await valid
    } catch (err) {
      result = err;
    }

    isValid = result === true;

    if (isValid == true) {
      submitted = validator.formSubmitted;
      validator.toHide = validator.errorsFor(element);
      validator.formSubmitted = submitted;
      validator.successList.push(element);
      validator.invalid[element.name] = false;
      validator.showErrors();
    } else {
      errors = {};
      message = result?.message || result?.error || result || validator.defaultMessage(element, { method: method, parameters: value });
      errors[element.name] = previous.message = message;
      validator.invalid[element.name] = true;
      validator.showErrors(errors);
    }

    previous.valid = isValid;
    validator.stopRequest(element, isValid);
  }

  evaluate();

  return "pending";
}, "Please enter a valid value.");